<template>
  <div>
    <div class="breadCurmb mt-3">

      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>

      <span>/</span>

      <label>{{ $t("Sales") }}</label>

      <span>/</span>

      <label style="cursor:pointer" @click="goBack">

        <span v-if="subscribeFrom">  {{ $t(subscribeFrom) }}  </span>

        <span v-else>
          {{ $t('Subscribes') }}
        </span>

      </label>

      <span>/</span>

      <label>{{$t("Add") }}</label>

    </div>

   <!-- Spinner Overlay -->
  <div v-if="saveClick">
    <div class="spinner-overlay">
      <div class="spinner"></div>
    </div>
    <h3 class="fixed-text">{{$t('Please wait until finish saving ...')}}</h3>
  </div>

  

    <v-row class="mt-3">
      <v-col md="12">
        <v-alert border="top" color="green lighten-2" dark>
          <h4>{{ $t("AddNewSubscribe") }}</h4>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="mt-3" v-if="zohoError">
      <v-col md="12">
        <v-alert border="top" color="red lighten-2" dark>
          <h4>{{ zohoError }}</h4>
        </v-alert>
      </v-col>
    </v-row>



    <v-card-text>
      <v-container v-if="errorsApi.length > 0">
        <h1>الاخطاء</h1>
        <div v-for="(error, index) in errorsApi" :key="index">
          {{ error }}
        </div>
      </v-container>
    </v-card-text>

    <v-card-text>
      <v-container fluid>

        <v-row v-if="selectedCustomer.name">
          <v-col class="py-0" :cols="4">
            <div >
              <label>{{ $t("SubscriberName") }}</label>
              <v-text-field  class="d-block my-2"
                  type="text" hide-details
                  dense
                  outlined disabled
                  v-model="selectedCustomer.name"
              ></v-text-field>
            </div>

          </v-col>
          <v-col class="py-0" :cols="4">
            <div >
              <label>{{ $t("Mobile") }}</label>
              <v-text-field  class="d-block my-2"
                  type="text" hide-details dense
                  outlined disabled v-model="selectedCustomer.phone_number"
              ></v-text-field>
            </div>

          </v-col>
          <v-col class="py-0" :cols="4">
            <div >
              <label>{{ $t("Email") }}</label>
              <v-text-field  class="d-block my-2"
                  type="text" hide-details
                  dense
                  outlined disabled
                  v-model="selectedCustomer.email"
              ></v-text-field>
            </div>

          </v-col>
        </v-row>
        <v-row>

<v-col :cols="4" class="py-0" >
  <div class="customer-search">
    <v-text-field
      class="my-9 mx-2"
        type="text"
        hide-details
        dense
        outlined
        v-model="searchQuery"
        @input="fetchCustomers"
        :placeholder="$t('Search by customer')"
    ></v-text-field>
    <ul v-if="showResults" class="customer-results">
      <li v-for="customer in listCustomers" :key="customer.id" @click="changeCustomer(customer.id)">
        {{ customer.name }} - {{customer.phone_number}}
      </li>
    </ul>
  </div>
</v-col>

  <!-- <v-col :cols="2" class="py-0" >
    
    <v-text-field
        class="my-9 mx-2"
        v-model="searchQuery"
        @input="fetchCustomers"
        :placeholder="$t('Search by customer')"
        dense
      ></v-text-field>
  </v-col> -->
  <!-- <v-col class="py-0" :cols="4">
    
    <div
      :class="{
        'has-error': errors.has('addEditValidation.list_customer'),
      }"
      class="my-2" 
    >
    <label>{{ $t("Customer") }}</label>

      <v-select
      class="mx-4 my-2"
        name="list_customer"
        data-vv-scope="addEditValidation"
        v-validate="'required'"
        :data-vv-as="$t('إختر العميل')"
        hide-details
        dense
        outlined
        v-model="formData.customer.id"
        item-value="id"
        :item-text="getItemText"
        :items="listCustomers"
        :search="false"
        clearable
        @change="changeCustomer(formData.customer.id)"
      ></v-select>
    </div>

    <div
      class="help-block mx-4"
      v-if="errors.has('addEditValidation.list_customer')"
    >
      {{ errors.first('addEditValidation.list_customer') }}
    </div>
  </v-col> -->

          
          <v-col class="py-0 my-2" :cols="6">
          <div
              :class="{
                    'has-error': errors.has(
                      `addEditValidation.NotMainIngredients`
                    ),
                  }"
          >
            <label>{{ $t("NotMainIngredients") }}</label>
            <v-autocomplete
                class="d-block my-2"
                name="NotMainIngredients"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('NotMainIngredients')"
                hide-details
                dense
                outlined
                v-model="formData.customer.not_ingredients"
                item-value="id"
                item-text="name"
                :items="notMainIngredientList"
                multiple
                clearable
            ></v-autocomplete>
          </div>

          <div
              class="help-block"
              v-if="errors.has(`addEditValidation.NotMainIngredients`)"
          >
            {{ errors.first(`addEditValidation.NotMainIngredients`) }}
          </div>
        </v-col>

        </v-row>

      </v-container>

    </v-card-text>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="py-0" :cols="3">
            <div
                :class="{
                    'has-error': errors.has(`addEditValidation.Package`),
                  }"
            >
              <label>{{ $t("Package") }}</label>
              <v-autocomplete
                  class="d-block my-2"
                  name="Package"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="$t('Package')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.subscribe.package_id"
                  item-value="id"
                  item-text="name"
                  :items="packageList"
                  clearable
                  @change="changePackage(formData.subscribe.package_id)"
              ></v-autocomplete>
            </div>

            <div
                class="help-block"
                v-if="errors.has(`addEditValidation.Package`)"
            >
              {{ errors.first(`addEditValidation.Package`) }}
            </div>
          </v-col>

          <template v-if="packageDetails">

             

            <v-col class="py-0" :cols="3">
              <div
                  :class="{
                      'has-error': errors.has(`addEditValidation.price`),
                    }"
              >
                <label>{{ $t("Price") }}</label>
                <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="price"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    disabled
                    :data-vv-as="$t('Price')"
                    hide-details
                    dense
                    outlined
                    v-model="packageDetails.price"
                ></v-text-field>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.price`)"
              >
                {{ errors.first(`addEditValidation.price`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="2">
              <div>
                <v-switch
                  class="d-block mt-8"
                  v-model="formData.subscribe.paid"
                  :value="1"
                  :false-value="0"
                  hide-details
                  dense
                  outlined
                  inset
                  :color="formData.subscribe.paid == 1 ? 'green' : 'red'"
                >
                  <template v-slot:label>
                    {{ formData.subscribe.paid == 1 ? $t('Paid') : $t('Not Paid') }}
                  </template>
                </v-switch>
              </div>
            </v-col>



            <v-col class="py-0" :cols="3"  >
              <div
                  :class="{
                      'has-error': errors.has(`addEditValidation.amount`),
                    }"
              >
                <label>{{ $t("Price") + ' المدفوع' }}</label>
                <v-text-field
                    class="d-block my-2"
                    type="number"
                    name="amount"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('Price')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.transaction.amount"
                ></v-text-field>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.amount`)"
              >
                {{ errors.first(`addEditValidation.amount`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="3" v-if="formData.subscribe.paid == 1">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.DepositeAccount`),
              }"
            >
              <label>{{ $t("DepositeAccount") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="DepositeAccount"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('DepositeAccount')"
                hide-details
                dense
                outlined
                v-model="formData.transaction.deposit"
                item-value="account_id"
                item-text="account_name"
                :items="depositAccountsList"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.DepositeAccount`)"
            >
              {{ errors.first(`addEditValidation.DepositeAccount`) }}
            </div>
          </v-col>

            <v-col class="py-0" :cols="3" v-if="formData.subscribe.paid == 1">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.Payment_mode`),
              }"
            >
              <label>{{ $t("Payment_mode") }}</label>
              <v-autocomplete
                class="d-block my-2"
                name="Payment_mode"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Payment_mode')"
                hide-details
                dense
                outlined
                v-model="formData.transaction.payment_mode"
                item-value="name"
                item-text="name"
                :items="paymentModesList"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.Payment_mode`)"
            >
              {{ errors.first(`addEditValidation.Payment_mode`) }}
            </div>
          </v-col>



              <v-col class="py-0" :cols="3">
              <div
                  :class="{
                      'has-error': errors.has(`addEditValidation.quantity`),
                    }"
              >
                <label>{{ $t("Quantity") }}</label>
                <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="quantity"
                    data-vv-scope="addEditValidation"
                    v-validate="'required|numeric|min_value:1'"
                    :data-vv-as="$t('Quantity')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.quantity"
                ></v-text-field>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.quantity`)"
              >
                {{ errors.first(`addEditValidation.quantity`) }}
              </div>
            </v-col>



            <v-col class="py-0" :cols="3" v-if="!$route.params.id">
              <div
                  :class="{
                      'has-error': errors.has(
                        `addEditValidation.number_of_days`
                      ),
                    }"
              >
                <label>{{ $t("NumberOfDaysPackage") }}</label>
                <v-text-field
                    class="d-block my-2"
                    type="text"
                    name="number_of_days"
                    data-vv-scope="addEditValidation"
                    v-validate="'numeric'"
                    :disabled="true"
                    :data-vv-as="$t('NumberOfDaysPackage')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.number_of_days"
                ></v-text-field>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.number_of_days`)"
              >
                {{ errors.first(`addEditValidation.number_of_days`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="3">
              <div
                  :class="{
                      'has-error': errors.has(`addEditValidation.start_date`),
                    }"
              >
                <label>{{ $t("StartDate") }}</label>
                <el-date-picker
                    v-validate="'required'"
                    style="width: 100%"
                    v-model="formData.subscribe.start_date"
                    type="date"
                    class="mt-2"
                >
                </el-date-picker>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.start_date`)"
              >
                {{ errors.first(`addEditValidation.start_date`) }}
              </div>
            </v-col>

<!--            <v-col class="py-0" :cols="3">-->
<!--              <div-->
<!--                  :class="{-->
<!--                      'has-error': errors.has(-->
<!--                        `addEditValidation.paid`-->
<!--                      ),-->
<!--                    }"-->
<!--              >-->
<!--                <label>{{ $t("SubscribeStatus") }}</label>-->
<!--                <v-autocomplete-->
<!--                    class="d-block my-2"-->
<!--                    name="paid"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="'required'"-->
<!--                    :data-vv-as="$t('SubscribeStatus')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.subscribe.paid"-->
<!--                    item-value="id"-->
<!--                    item-text="name"-->
<!--                    :items="subscribeStatusList"-->
<!--                    clearable-->
<!--                ></v-autocomplete>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.paid`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.paid`) }}-->
<!--              </div>-->
<!--            </v-col>-->

            <v-col
                class="py-0"
                :cols="3"
                v-if="packageDetails && $route.params.id"
            >
              <div>
                <label>{{ $t("SubscriptionsDays") }}</label>
                <v-alert color="blue">
                  {{ formData.subscribe.count_subscriptions_day }}
                </v-alert>
              </div>
            </v-col>

            <v-col
                class="py-0"
                :cols="2"
                v-if="packageDetails && $route.params.id"
            >
              <div>
                <label>{{ $t("RemindDays") }}</label>
                <v-text-field
                    v-if="formData.subscribe.is_remind_days"
                    class="d-block my-2"
                    type="text"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.count_of_remind_day" color="orange">
                </v-text-field>
                <v-alert v-else color="orange">
                  {{ formData.subscribe.remind_days }}
                </v-alert>
              </div>
            </v-col>
            <v-col
                class="py-0"
                :cols="1"
                v-if="packageDetails && $route.params.id"
            >
              <div>
                <label>{{ $t("IsEdit") }}</label>
                <v-checkbox
                    @change="changeRemindDays(formData.subscribe.is_remind_days)"
                    v-model="formData.subscribe.is_remind_days">
                </v-checkbox>
              </div>
            </v-col>

<!--            <v-col class="py-0" :cols="3">-->
<!--              <div-->
<!--                  :class="{-->
<!--                      'has-error': errors.has(`addEditValidation.total`),-->
<!--                    }"-->
<!--              >-->
<!--                <label>{{ $t("Total") }}</label>-->
<!--                <v-text-field-->
<!--                    class="d-block my-2"-->
<!--                    type="text"-->
<!--                    name="total"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="''"-->
<!--                    disabled-->
<!--                    :data-vv-as="$t('Total')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.subscribe.total"-->
<!--                ></v-text-field>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.total`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.total`) }}-->
<!--              </div>-->
<!--            </v-col>-->

            <v-col class="py-0" :cols="4">
              <div
                  :class="{
                      'has-error': errors.has(`addEditValidation.days`),
                    }"
              >
                <label>{{ $t("DaysSelected") }}</label>
                <v-autocomplete
                    class="d-block my-2"
                    name="days"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('DaysSelected')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.days"
                    item-value="id"
                    item-text="name"
                    :items="dayList"
                    @change="dayListChanged"
                    multiple
                    clearable
                ></v-autocomplete>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.days`)"
              >
                {{ errors.first(`addEditValidation.days`) }}
              </div>
            </v-col>

            <v-col class="py-0" :cols="3">
              <div
                  :class="{
                      'has-error': errors.has(`addEditValidation.address_id`),
                    }"
              >
                <label>{{ $t("عنوان التوصيل") }}</label>
                <v-autocomplete
                    class="d-block my-2"
                    name="address_id"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="$t('address_id')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.customer.address_id"
                    item-value="id"
                    item-text="type"
                    :items="addressesList"
                    clearable
                ></v-autocomplete>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.address_id`)"
              >
                {{ errors.first(`addEditValidation.address_id`) }}
              </div>
            </v-col>


            <v-col class="py-0" :cols="2">
              <div>
                <label>{{ $t("اليوم الوطني") }}</label>
                <v-checkbox
                    @change="changeNationalDay(formData.subscribe.national_day)"
                    v-model="formData.subscribe.national_day">
                </v-checkbox>
              </div>
            </v-col>

            <v-col class="py-0" :cols="6">
              <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.notes`),
                  }"
              >
                <label>{{ $t("Notes") }}</label>
                <v-textarea
                    class="d-block my-2"
                    type="text"
                    name="notes"
                    rows="4"
                    data-vv-scope="addEditValidation"
                    v-validate="''"
                    :data-vv-as="$t('Notes')"
                    hide-details
                    dense
                    outlined
                    v-model="formData.subscribe.notes"
                ></v-textarea>
              </div>

              <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.notes`)"
              >
                {{ errors.first(`addEditValidation.notes`) }}
              </div>
            </v-col>


            <v-container fluid>
              <div>
                <el-button
                    @click="addNew"
                    type="primary float-right mb-5"
                >{{ $t("Add") }}</el-button
                >
              </div>

              <table id="pacakgeFeaturesTable">
                <tr>
                  <th>#</th>
                  <th>اسم المجموعة</th>
                  <th>الكمية</th>
                  <th>العمليات</th>
                </tr>

                <tr
                    v-for="(l, lIndex) in formData.subscribe.group_subscribe"
                    :key="lIndex"
                >
                  <td>
                    {{ lIndex + 1 }}
                  </td>
                  <td>
                    <div
                        :class="{
                            'has-error': errors.has(`addEditValidation.group${lIndex}`),
                          }"
                    >
                      <label>{{ $t("Group") }}</label>
                      <v-autocomplete
                          class="d-block my-2"
                          :name="`group${lIndex}`"
                          data-vv-scope="addEditValidation"
                          v-validate="'required'"
                          :data-vv-as="$t('Group')"
                          hide-details
                          dense
                          outlined
                          v-model="l.id"
                          item-value="id"
                          item-text="name"
                          :items="listGroup"
                          clearable
                      ></v-autocomplete>
                    </div>

                    <div
                        class="help-block"
                        v-if="errors.has(`addEditValidation.group${lIndex}`)"
                    >
                      {{ errors.first(`addEditValidation.group${lIndex}`) }}
                    </div>
                  </td>
                  <td>
                    <div
                        :class="{
                            'has-error': errors.has(
                              `addEditValidation.quantity${lIndex}`
                            ),
                          }"
                    >
                      <label>{{ $t("Quantity") }}</label>
                      <v-text-field
                          class="d-block my-2"
                          :name="`quantity${lIndex}`"
                          data-vv-scope="addEditValidation"
                          v-validate="'required|numeric'"
                          :data-vv-as="$t('Quantity')"
                          hide-details
                          dense
                          outlined
                          v-model="l.quantity"
                      ></v-text-field>
                    </div>

                    <div
                        class="help-block"
                        v-if="errors.has(`addEditValidation.quantity${lIndex}`)"
                    >
                      {{ errors.first(`addEditValidation.quantity${lIndex}`) }}
                    </div>
                  </td>
                  <td>
                    <v-icon
                        class="my-0 mr-2 ml-2"
                        dense
                        @click="removeRow(lIndex)"
                        color="danger"
                    >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </table>
            </v-container>
          </template>
        </v-row>
      </v-container>
    </v-card-text>


    <v-container fluid>
      <v-row class="float-left">
        <v-btn   class="mr-2 ml-5" color="success" dark
            outlined small @click="save">
          {{ $t("Save") }}
        </v-btn>
        <v-btn
          class="mx-5"
          color="danger"
          dark
          outlined
          small
          @click="saveWithBill"
        >
          {{ $t("SaveWithBill") }}
        </v-btn>
      </v-row>
    </v-container>


  </div>

</template>
<script>
import moment from "moment";
export default {
  name: "createSubscription",
  data() {
    return {
      zohoError: null,
      depositAccountsList: [],
      paymentModesList: [],
      searchQuery: '',
      showResults: false,
      searchTimeout: null,
      errorsApi: [],
      user: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : [],
      subscribeFrom: localStorage.getItem('addSubscribeFrom') || 'all',
      defaultDays: [],
      listCustomers: [],
      loadClicked: false,
      saveClick: false,
      msg: 'برجاء الانتظار لحين اكتمال العملية',
      activeTab: 0,
      selectedCustomer: {
        name: null,
        phone_number: null,
        email: null
      },
      formData: {
        customer: {
          id: null,
          not_ingredients: [],
          address_id:null
        },
        subscribe: {
          quantity: null,
          package_id: null,
          day_status: 1,
          discount: null,
          days: [],
          start_date: null,
          total: null,
          number_of_days: null,
          remind_days: null,
          paid: 0,
          group_subscribe: [],
          is_remind_days: false,
          count_of_remind_day: 0,
          national_day: false,
          notes: null,
          save_with_zoho: false,
        },
        transaction: {
          amount: 0,
          deposit: null,
          payment_mode : null,
          national_day :false,
          notes:null
        },
      },
      packageList: [],
      cityList: [],
      addressesList: [],
      neighborhoodList: [],
      PeopleGroup: [],
      notMainIngredientList: [],
      packageDetails: null,
      listGroup: [],
      subscribeStatusList: [
        {
          id: 1,
          name: this.$t("Paid"),
        },
        {
          id: 0,
          name: this.$t("NotPaid"),
        },
      ],
      dayList: [
        { id: 6, name: this.$t("Saturday") },
        { id: 0, name: this.$t("Sunday") },
        { id: 1, name: this.$t("Monday") },
        { id: 2, name: this.$t("Tuesday") },
        { id: 3, name: this.$t("Wedensday") },
        { id: 4, name: this.$t("Thursday") },
      ],
    };
  },
  


  
  methods: {
    getItemText(item,) {
      return `${item.name}   ${item.phone_number}`;
    },
    changeRemindDays(val) {
      if(val == false) {
        this.formData.subscribe.count_of_remind_day= this.formData.subscribe.remind_days;
      }
    },
    changeNationalDay(val) {
      this.formData.subscribe.national_day == val;
    },
    goBack() {
      this.$router.go(-1);
    },
    // changeRenewSubscribe(val) {
    //   if (val == 2) {
    //     this.formData.subscribe.days = this.defaultDays;
    //     this.formData.subscribe.renew_date = null;
    //     this.formData.subscribe.renew_day_numbers = null;
    //   }
    //   else {
    //     this.formData.subscribe.renew_date = new Date();
    //     this.formData.subscribe.renew_day_numbers = this.formData.subscribe.remind_days;
    //   }
    // },
    dayListChanged() {
      // this.formData.subscribe.renewSubscribe = 1;
      // this.formData.subscribe.renew_date = new Date();
      // this.formData.subscribe.renew_day_numbers = this.formData.subscribe.remind_days;
    },
    removeRow(index) {
      this.formData.subscribe.group_subscribe.splice(index, 1);
    },
    addNew() {
      let listObj = {
        id: null,
        quantity: null,
      };
      this.formData.subscribe.group_subscribe.push(listObj);
    },
    next() {
      this.activeTab += 1;
    },
    changeDiscount() {
      if (
          this.packageDetails &&
          this.packageDetails.price &&
          this.formData &&
          this.formData.subscribe &&
          this.formData.subscribe.discount
      ) {
        let total =
            parseInt(this.packageDetails.price) -
            parseInt(this.formData.subscribe.discount);
        this.formData.subscribe.total = total;
      } else {
        this.formData.subscribe.total = this.packageDetails.price;
      }
    },
    getNeighborhoodList(id) {
      this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
        this.neighborhoodList = res.data;
      });
    },
    changePackageInitalize(id) {
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
      });
    },
    changePackage(id) {
      this.$store.dispatch(`subscribe/packageDetails`, id).then((res) => {
        this.packageDetails = res.data;
        this.formData.subscribe.days = res.data.days;
        this.formData.subscribe.group_subscribe = res.data.details;
        this.formData.subscribe.number_of_days = res.data.number_of_days;


      });
    },
    changeCustomer(id) {
      this.formData.customer.address_id  = null;
      const selectedCustomer = this.listCustomers.find(customer => customer.id === id);
      this.selectedCustomer = selectedCustomer
      this.addressesList = selectedCustomer.addresses
      this.searchQuery = selectedCustomer.name + " - " + selectedCustomer.phone_number;
      this.formData.customer.id = selectedCustomer.id;
      this.showResults = false;
    },
    
    

    async fetchCustomers() {
      this.listCustomers = [];
      this.formData.customer.id= null;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a delay to debounce the API request
      this.searchTimeout = setTimeout(async () => {
        try {
          const response = await this.$store.dispatch('customer/searchCustomers', {
            search: this.searchQuery
          });
          this.listCustomers = response.data;  // Assign fetched data to the list
          this.showResults = true;
        } catch (error) {
          console.error('Error fetching customers:', error);
        }
      }, 500);  // Delay of 500ms
    },

    customFilter(item, search, textKey) {
      // You can use this function to filter the items. In this case, the search is handled by the backend.
      // You can return `true` here since the search is being handled by the fetchCustomers method.
      return true;
    },

    save() {
    this.handleSave(false);
  },
  
  saveWithBill() {
    this.handleSave(true);
  },

  handleSave(saveWithZoho) {
    this.loadClicked = true;
    this.saveClick = true;

    this.$validator.validateAll("addEditValidation").then((valid) => {
      if (!valid) {
        this.resetLoadingState();
        return this.notifyCustomError("Error", "Validation Errors");
      }

      let sendData = JSON.parse(JSON.stringify(this.formData));
      this.prepareSubscriptionData(sendData);

      let contactZoho = {
        contact_name: this.selectedCustomer.name,
        contact_persons: [{ phone: this.selectedCustomer.phone_number }],
      };

      if (this.zohoError == null) {
        this.$store.dispatch(`zoho/createContact`, contactZoho)
          .then((res) => {
            let contact_id = res.contact.contact_id;
            sendData.customer.contact_id = contact_id;
            sendData.subscribe.save_with_zoho = saveWithZoho;
            this.storeSubscriptionData(sendData);
          })
          .catch(this.handleError)
          .finally(this.resetLoadingState);
      } else {
        // Handle case with Zoho error (no contact id)
        sendData.customer.contact_id = null;
        this.storeSubscriptionData(sendData);
      }
    });
  },

  prepareSubscriptionData(sendData) {
    if (sendData && sendData.subscribe && sendData.subscribe.paid == null) {
      sendData.subscribe.paid = 0;
    }

    if (sendData.subscribe.start_date) {
      sendData.subscribe.start_date = moment(sendData.subscribe.start_date).format("YYYY-MM-DD");
    }
  },

  storeSubscriptionData(sendData) {
    this.$store.dispatch(`newSubscribe/store`, sendData)
      .then((res) => {
        this.notifySuccess("Save", res);
        this.$router.push({ name: "subscribe" });
      })
      .catch(this.handleApiError)
      .finally(this.resetLoadingState);
  },

  handleApiError(error) {
    this.saveClick = false;
    let errorsArray = [];

    if (error.response && error.response.data) {
      const responseData = error.response.data;
      if (Array.isArray(responseData.errors)) {
        errorsArray = responseData.errors;
      } else if (typeof responseData.errors === 'object') {
        errorsArray = Object.values(responseData.errors);
      } else if (responseData.message) {
        errorsArray.push(responseData.message);
      }
    } else {
      console.error('Unexpected error:', error);
      errorsArray.push('An unexpected error occurred.');
    }

    this.errorsApi = errorsArray;
  },

  handleError() {
    this.saveClick = false;
  },

  resetLoadingState() {
    this.loadClicked = false;
  }

  },
  created() {

    this.$store
      .dispatch(`zoho/checkAuthorized`)
      .then((res) => {
        this.zohoError= null;


      this.$store
        .dispatch(`zoho/getDepositToAccounts`)
        .then((res) => {
          this.depositAccountsList= res;
        })
        .catch((err) => {
        console.log(err);
      });

      this.$store
        .dispatch(`zoho/getPaymentModes`)
        .then((res) => {
          this.paymentModesList= res.payment_modes;

        })
        .catch((err) => {
        console.log(err);
      });


      })
      .catch((err) => {
        console.log(err);
        this.zohoError= this.$t('Not Connect on Zoho')

      });

    let check_permessions= false;

      if (
          (this.user &&
              this.user.permissions &&
              !(this.user.permissions.includes("store_subscribe"))
          )
      ){

        this.$router.push({name: 'subscribe'});

        this.notifyCustomError("Error", "لا يوجد لديك صلاحية");

      }


      this.$store.dispatch(`subscribe/mainIngredientList`).then((res) => {
        this.mainIngredientList = res.data;
      });
      this.$store.dispatch(`subscribe/notMainIngredientList`).then((res) => {
        this.notMainIngredientList = res.data;
      });


      this.$store.dispatch(`subscribe/packageList`).then((res) => {
        this.packageList = res.data;
      });

      this.$store.dispatch(`package/listGroup`).then((res) => {
        this.listGroup = res.data;
      });

      // this.$store.dispatch(`customer/listValidAddress`).then((res) => {
      //   this.listCustomers = res.data;
      // });


    },



};
</script>


<style>
#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse; 
  width: 100%;
}

#pacakgeFeaturesTable td,
#pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04aa6d;
  color: white;
}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above everything */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #81C784; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* Spin Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Optionally, disable interaction with the rest of the page while the spinner is active */
.page-content {
  pointer-events: none;
}

.fixed-text {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #b5f5b9;
  margin-top: 15px;
  z-index: 1001; /* Ensure it's on top of the spinner overlay */
}



.customer-search {
  position: relative;
}
.customer-results {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  width: calc(100% - 2px);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}
.customer-results li {
  padding: 8px 12px;
  cursor: pointer;
}
.customer-results li:hover {
  background-color: #f0f0f0;
}
</style>